import React from "react"
import { graphql } from "gatsby"
import tw from "twin.macro"
import SEO from "../components/SEO"
import { Layout } from "../components/Layout"
import { ArticleView } from "../components/ArticleView"
import { Article, SiteMetadata } from "../model/cms"
import { ChildImageSharp } from "../components/Image"

const Container = tw.div`px-4`

interface ArticleTemplateProps {
  data: {
    site: {
      siteMetadata: SiteMetadata
    }
    article: Article
  }
}

export default function ArticleTemplate({ data }: ArticleTemplateProps) {
  const openGraph = {
    type: "article",
    title: data.article.title,
    description: data.article.description,
    published_time: data.article.createdAt,
    author_twitter: data.article.author.twitter,
    twitter: data.site.siteMetadata.twitterUsername,
    image: {
      url: data.article.socialImage.url,
      width: data.article.socialImage.width,
      height: data.article.socialImage.height,
    },
  }

  return (
    <Layout>
      <SEO
        title={`${data.article.title} | Long Rock Labs`}
        description={data.article.description}
        openGraph={openGraph}
        meta={[]}
      />
      <Container>
        <ArticleView article={data.article} singleArticle={true} />
      </Container>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($slug: String!) {
    site {
      siteMetadata {
        siteUrl
        twitterUsername
      }
    }
    article: graphCmsArticle(slug: { eq: $slug }) {
      createdAt
      slug
      title
      category
      content
      excerpt
      description
      author {
        name
        title
        avatar {
          localFile {
            childImageSharp {
              gatsbyImageData(width: 256)
            }
          }
        }
      }
      socialImage {
        url
        width
        height
        localFile {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`
